<template>
    <div class="page pd">
        <div class="panel">
            <div class="header">User Videos</div>
            <div class="subheader pd">
                Please select the video you want to watch
            </div>
            <div class="cols">
                <div class="items">
                    <div class="item" v-for="item in items" @click="selected = item"
                         :class="{active:selected && selected.name === item.name}">
                        <div class="item-name">{{ item.name }}</div>
                        <div class="arrow">
                            <awesome-icon icon="chevron-right"/>
                        </div>
                    </div>
                </div>
                <div class="video-window">
                    <video v-if="selected" :src="videoPath + selected.video" autoplay muted controls :key="selected.name"></video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "user-videos",

  data() {
    return {
      items: [
        {name: 'Adding webpages', video: 'adding-webpages.mp4'},
        {name: 'Creating players', video: 'creating-players.mp4'},
        {name: 'Creating playlists', video: 'creating-playlists.mp4'},
        {name: 'How to add media items', video: 'how-to-add-media-items.mp4'},
        {name: 'How to change your password', video: 'how-to-change-your-password.mp4'},
        {name: 'How to create a message', video: 'how-to-create-a-message.mp4'},
        {name: 'Message Management', video: 'message-management.mp4'},
        {name: 'Player Dashboard', video: 'player-dashboard.mp4'},
        {name: 'Player Groups', video: 'player-groups.mp4'},
        {name: 'User Groups', video: 'user-groups.mp4'},
      ],
      videoPath: 'https://s3.eu-west-2.amazonaws.com/uat-nr-s3/video/',
      selected: null,
    }
  },

}
</script>

<style scoped lang="scss">
.subheader {
    font-weight: 700;
    margin-bottom: 28px;
}

.cols {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .items {
        background: #fafafa;

        .item {
            background: #fff;
            padding: 0.8em 32px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            display: flex;

            .item-name {
                flex: 1;
                margin-right: 1em;
            }

            &:hover {
                background: $color-primary;
                color: #fff;
            }

            &.active {
                background: $color-primary;
                color: #fff;
            }
        }
    }

    .video-window {
        flex: 1;
        background: #eee;
        min-height: 20em;
        padding: 1em;

        video {
            width: 100%;
        }
    }
}


</style>